import { getClientImages, loadClientStats, loadGlobalStats, getImageText, fetchOcrResultValues, invalidateOcrField } from '@/services/OcrService'
import moment from 'moment'


function getRecognizedData (image) {
  const removedKeys = [
    'id',
    'filename',
    'serial',
    'date',
    'src',
    'boxSrc'
  ]

  const recognizedData = Object.keys(image)
    .filter(field => removedKeys.indexOf(field) === -1)
    .map((field) => {
      const value = image[field]
      const hasValue = value !== null
      const invalidField = field[0].toUpperCase() + field.slice(1)
      return {
        valid: hasValue,
        field: field,
        value: value,
        invalidated: image[`invalid${invalidField}`]
      }
    })

  return recognizedData

}


export default {
  state: {
    date: moment(),
    images: [],
    allImages: [],
    selectedImage: null,
    recognizedData: [],
    ocrText: null,
    dialog: false,
    stats: [],
    ocrResultStats: [],
    ocrResultValues: null,
    filter: null,
  },
  mutations: {
    setStats (state, stats) {
      state.stats = stats
    },
    setSelectedImage (state, selected) {
      state.selectedImage = selected
    },
    setImages (state, images) {
      state.allImages = images
      if (state.filter !== null) {
        const filtered = state.allImages.filter((image) => {
          if (image[state.filter] === null) {
            return true
          }
          return false
        })
        state.images = filtered
      } else {
        state.images = state.allImages
      }
    },
    setOcrResultValues (state, values) {
      state.ocrResultValues = values
    },
    setOcrResultStats (state, values) {
      state.ocrResultStats = values
    },
    setRecognizedData (state, data) {
      state.recognizedData = data
    },
    setDialog (state, open) {
      state.dialog = open
    },
    setFilter (state, filter) {
      state.filter = filter
    },
    setOcrText (state, text) {
      state.ocrText = text
    },
    setDate (state, date) {
      state.date = date
    }
  },

  actions: {
    setOcrDate ({ commit }, date) {
      commit('setDate', date)
    },
    setFilter ({ commit, state }, filter) {
      if (filter === state.filter) {
        commit('setFilter', null)
      } else {
        commit('setFilter', filter)
      }
      commit('setImages', state.allImages)
    },
    fetchImages ({ state, commit }, customer) {
      getClientImages(customer, state.date.format('YYYY-MM-DD')).then(images => {
        commit('setImages', images)
      })
    },
    fetchOcrText ({ commit }, image) {
      getImageText(image).then(ocrText => {
        commit('setOcrText', ocrText)
      })
    },
    setRecognizedData ({ commit }, data) {

      commit('setRecognizedData', data)

    },
    showImage ({ dispatch, commit }, image) {
      commit('setDialog', true)
      commit('setSelectedImage', image)
      const recognizedData = getRecognizedData(image)
      fetchOcrResultValues(recognizedData).then((allResults) => {
        commit('setOcrResultValues', allResults)
      })
      //fetchOcrResultStats(recognizedData).then((allResults) => {
      //  commit('setOcrResultStats', allResults)
      //})
      commit('setRecognizedData', recognizedData)
      dispatch('fetchOcrText', image)
    },
    hideImage ({ commit }) {
      commit('setDialog', false)
      commit('setSelectedImage', null)
      commit('setRecognizedData', [])
      commit('setOcrText', null)
      commit('setOcrResultValues', null)
    },
    fetchStats ({ state, commit }, customer) {

      const applyStats = stats => commit('setStats', stats)
      if (customer !== null) {
        loadClientStats(customer.serial, state.date.format('YYYY-MM-DD')).then(applyStats)
      } else {
        loadGlobalStats(state.date.format('YYYY-MM-DD')).then(applyStats)
      }
    },
    setSelectedCustomer ({ commit }, customer) {
      commit('setSelectedCustomer', customer)
    },
    invalidateOcrField ({ commit, state }, imageData) {
      invalidateOcrField(imageData).then((result) => {
        const formated = getRecognizedData(result)
        commit('setSelectedImage', { ...state.selectedImage, ...result })
        commit('setRecognizedData', formated)
      })

    }
  }
}
