import moment from 'moment'
import config from '@/utils/ApiUtils'
import { fetchWithToken } from '@/utils/Keycloak'
import jsonConfig from '@/assets/config.json'

export function getImageText (image) {
  return fetch(`${jsonConfig.ocrImageApi}/text/${image.filename}?model=ocr_512_1024_chars.ckpt`).then(
    r => r.json()
  ).then(
    payload => payload.text
  )
}


export function invalidateOcrField (ocrData) {
  return fetch(`${config().ocrApi}/invalidate/${ocrData.id}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(ocrData)
  }).then((response) => {
    return response.json()
  })
}

export function getClientImages (serial, date = moment().format('YYYY-MM-DD')) {
  return fetch(`${config().ocrApi}?serial=${serial}&date=${date}`)
    .then(r => r.json())
    .then(ocrResults => {
      return ocrResults.map((result) => {
        return {
          ...result,
          src: `${config().imageApi}/${result.filename}`,
          boxSrc: `${jsonConfig.ocrImageApi}/boxes/${result.filename}`
        }
      })

    })
}

const mapKeysToRoute = {
  'idPoisson': 'poisson',
  'idConditionnement': 'conditionnement',
  'idTaille': 'taille',
  'idPreparation': 'preparation',
  'idLatinPoisson': 'latin',
  'idEngins': 'engin',
  'idPeche': 'capture',
  'idZone': 'zone',
  'idSousZone': 'souszone',
  'idProvenance': 'provenance'
}

const mapKeysToStats = {
  'idPoisson': 'idpoisson',
  'idConditionnement': 'idconditionnement',
  'idTaille': 'idtaille',
  'idPreparation': 'idpreparation',
  'idLatinPoisson': 'idlatin',
  'idEngins': 'idengins',
  'idPeche': 'idcapture',
  'idZone': 'idzone',
  'idSousZone': 'idsouszone',
  'idProvenance': 'idprovenance'
}
export function fetchOcrResultValues (ocrResult) {
  const promises = ocrResult
    .filter(({ value, field }) => value !== null && field.indexOf('invalid') === -1)
    .map(({ field, value }) => {
      const route = mapKeysToRoute[field]
      return fetchWithToken(`${config().fishBakend}/${route}/${value}`, {})
        .then(r => r.json())
        .then((result) => ({ ...result, field: field }))
    })
  return Promise.all(promises).then((results) => {
    return results.map((result) => {
      if (result.idPoisson && result.idCategoriePoisson) {
        return { ...result, nom: result.denom[0].nom }
      }
      if (result.idTaille) {
        return { ...result, nom: result.taille }
      }
      return result
    })
  })
}
const statsField = [
  'idTaille',
  'idPoisson',
  'idConditionnement',
  'idLatinPoisson',
  'idEngins',
  'idPeche',
  'idZone',
  'idSousZone'
]

export function fetchOcrResultStats (ocrResult) {
  const idPoisson = (ocrResult.find(({ field }) => field === 'idPoisson') || {}).value
  const idConditionnement = (ocrResult.find(({ field }) => field === 'idConditionnement') || {}).value

  const promises = ocrResult
    .filter(({ value, field }) => value !== null && field.indexOf('invalid') === -1)
    .filter(({ field }) => statsField.find(value => value === field))
    .map(({ field, value }) => {
      const route = mapKeysToRoute[field]
      return fetchWithToken(`${config().fishBakend}/${route}/?idPoisson=${idPoisson}&idConditionnement=${idConditionnement}`, {})
        .then(r => r.json())
        .then((result) => {
          const percent = (result.find(percent => percent[mapKeysToStats[field]] === value) || {}).percent
          return { result: percent || 0, field: field }
        })
    })
  return Promise.all(promises).then((results) => {
    return results
  })
}
export function loadClientStats (serial, date) {
  return fetch(`${config().ocrStatsApi}?date=${date}&serial=${serial}`).then(r => r.json()).then(stats => {
    const formated = Object.keys(stats).map(key => {
      return { name: key, value: parseInt(stats[key]) }
    })
    return formated
  })
}

export function loadGlobalStats (date) {
  return fetch(`${config().ocrStatsApi}/global?date=${date}`).then(r => r.json()).then(stats => {
    const formated = Object.keys(stats).map(key => {
      return { name: key, value: parseInt(stats[key]) }
    })
    return formated
  })
}
